@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;1,500;1,600;1,700;1,800&display=swap');

body{font-family: 'Plus Jakarta Sans', sans-serif !important;}
.container {margin-bottom: 0px !important; padding: 0 15px !important;}


.landining_main .btn.white_btn{border: 2px solid #E0DEF7 !important;background: #fff !important; color: #000929 !important;}
.landining_main .btn.white_btn:hover{border: 2px solid #6341E0 !important;background: #6341E0 !important; color: #ffffff !important;}
.landining_main .btn.blue_btn{border: 2px solid #6341E0 !important;background: #6341E0 !important; color: #fff !important;}
.landining_main .btn.blue_btn:hover{border: 2px solid #E0DEF7 !important;background: #fff !important; color: #000929 !important;}



/* header */
.Landing_header .btn.white_btn{border: 2px solid #E0DEF7 !important;background: #fff !important; color: #000929 !important;}
.Landing_header .btn.white_btn:hover{border: 2px solid #6341E0 !important;background: #6341E0 !important; color: #ffffff !important;}
.Landing_header .btn.blue_btn{border: 2px solid #6341E0 !important;background: #6341E0 !important; color: #fff !important;}
.Landing_header .btn.blue_btn:hover{border: 2px solid #E0DEF7 !important;background: #fff !important; color: #000929 !important;}
.Landing_header .nav-link.dropdown-toggle{ background: url('../../../src/Images/drdn.png') !important; background-repeat: no-repeat !important; 
  background-size: 13px !important;background-position: right center !important;padding-right: 16px !important; border: none;}
.Landing_header .dropdown-toggle::after{ border: transparent !important;}
.Landing_header .navbar-brand img { width: 113px; height: auto;}
.Landing_header .navbar-expand-lg .navbar-nav .nav-link {color:#000929 !important; font-size: 16px;font-style: normal !important;
  font-weight: 400;line-height: 150%;padding: 10px 33px;font-family: 'Plus Jakarta Sans', sans-serif !important;}
.Landing_header .rt_btns {margin: 0px; display: flex;gap: 10px;justify-content: end;width: auto;}
.Landing_header .rt_btns .btn { max-width: 90px;  border-radius: 8px !important;}
.Landing_header .navbar.navbar-expand-lg.navbar-light {border-bottom: 1px solid #E0DEF799;padding: 15px 0;}
.Landing_header .navbar-toggler:focus { box-shadow: none !important; border: 3px solid #6341e0;}
.Landing_header .navbar-toggler { box-shadow: none !important; border: 3px solid #6341e0;padding: 2px 6px;}


/*  */
.landining_main {background: url('../../../src/Images/ma_p.jpg');background-size: 50%;background-position: right top;
  background-repeat: no-repeat;min-height: calc(100vh - 80px); padding:60px 0 140px 0;}

.landining_left h1 {font-size: 64px;font-style: normal !important;font-weight: 700;line-height: 110%;letter-spacing: -0.64px;
font-family: 'Plus Jakarta Sans', sans-serif !important;padding-bottom: 40px; color: #000929;}
.landining_left p {font-size: 20px;font-style: normal;font-weight: 500; line-height: 160%; /* 32px */ letter-spacing: -0.1px; 
font-family: 'Plus Jakarta Sans', sans-serif !important; color: #000929;}
.landining_main_grid {display: grid;grid-template-columns: 1fr 1fr;grid-gap: 25px;padding-bottom: 60px;}
.renters h2 { font-size: 32px; font-style: normal !important; font-weight: 700; line-height: 125%; letter-spacing: -0.32px; 
color: #7065F0;border-left: 3px solid #E0DEF7;padding: 3px 15px;}
.renters h2 span{ font-size: 16px;font-style: normal;font-weight: 500;line-height: 150%; display: block; color:#6C727F; }
.renters {display: flex;gap: 20px;padding: 30px 0;}
.property_card h2 {color: #7065F0;font-size: 24px;font-style: normal !important;font-weight: 800;line-height: 150%;
letter-spacing: -1px;padding: 15px 0;}
.property_card h2 span {color:#6C727F;font-size: 14px;font-style: normal;font-weight: 500;line-height: 140%; margin-left: 5px; }
.property_card h4 {color:  #000929;font-family: Plus Jakarta Sans;font-size: 24px;font-style: normal;font-weight: 700;
  line-height: 150%;letter-spacing: -0.24px; text-align: left;}
.property_card p { color: #000929;font-family: Plus Jakarta Sans;font-size: 16px;font-style: normal;font-weight: 500;line-height: 150%;}
.property_card h4 { text-align: left;margin: 0px; padding-bottom: 8px;}
.card_footer span {color: #6C727F;font-size: 14px; font-weight: 500;line-height: 140%;}
.card_footer {display: flex;gap: 30px;padding: 10px 0 5px 0;}
.card_content {width: 100%;padding: 25px 20px;}
.property_card {max-width: 380px;border-radius: 8px;border: 1.5px solid var(--shades-of-purple-purple-96, #F0EFFB);background:#FFF;}
.property_card > img { width: 100%;border-radius: 8px 8px 0 0;}
.property_card.float_card {position: absolute; bottom: -200px; right: -100px; scale: .6; border-radius: 10px; border: 2px solid #7065F0;
 background: #FFF;padding: 3px;}
.landing_tabs .nav.nav-tabs {gap: 23px;}
.landing_tabs .nav-tabs .nav-item .nav-link {color: #000929 !important;font-family: Plus Jakarta Sans !important;font-size: 18px;
font-weight: 600;line-height: 145%;letter-spacing: -0.09px;border-bottom: 2px solid transparent;padding: 8px 16px;
border-width: 0px 0px 2px 0px !important;}
.landing_tabs .nav-tabs .nav-item.show .nav-link, .landing_tabs .nav-tabs .nav-link.active{border-bottom: 2px solid #7065F0;
color: #7065F0 !important; border-width: 0px 0px 2px 0px !important;}
.sear_ch label {color: rgba(0, 22, 25, 0.70);font-size: 16px;font-weight: 500;line-height: 150%;}
.sear_ch {display: flex;gap: 30px;}
.sear_ch > div {display: grid; grid-gap: 6px;}
.sear_ch input {border: 2px solid #E0DEF7;border-radius: 0px; border-width: 0px 0px 2px 0px !important;}
.sear_ch input:focus-visible {outline: none;}
.landing_tabs { box-shadow: 0px 5px 5px 1px #E0DEF7; padding: 20px;background: #fff; border-radius: 8px; max-width: 940px;}
.footerl_inner p {color: #21114D;font-family: Inter !important;font-size: 14px;font-weight: 400;line-height: 20px;text-align: center;
width: 100%;display: block;}
.footerl {background: #fff;padding: 33px 0;}
.footerl_inner p a {color: #21114D;text-decoration: underline !important;}
.footerl_inner p a.ma_il {text-decoration: none !important;}

.terms-condiions p,.terms-condiions, .terms-condiions ol li, .terms-condiions ul li
{
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 25.6px;
  letter-spacing: -0.005em;
  
}
.landining_main.terms-fixed {background: url('../../../src/Images/ma_p.jpg');background-size: 50% auto; background-attachment: fixed; background-position: right top;
  background-repeat: no-repeat;min-height: calc(100vh - 80px); padding:60px 0 140px 0;}
  .terms-fixed  .landining_main_grid {display: grid;grid-template-columns: 1.3fr 1fr;grid-gap: 25px;padding-bottom: 60px;}
.terms-condiions
{
  max-width: 685px; 
  
}
.terms-condiions p
{
  font-weight: 700 !important;
}
.terms-condiions ul
{
  list-style-type: disc;
}
.terms-heading
{
  max-width: 500px;
}
/* Responsive*/
@media (max-width: 1400px){
  .landing_tabs { max-width: 850px;}
  .sear_ch input {width: 100%; }
  .landining_main .sear_ch .btn.blue_btn { min-width: 184px;}
  .landining_main.terms-fixed {background: url('../../../src/Images/ma_p.jpg');background-size: 70% auto;background-attachment: fixed; background-position: right top;
    background-repeat: no-repeat;min-height: calc(100vh - 80px); padding:60px 0 140px 0; }
  
}
@media (max-width: 1200px){
.Landing_header .navbar-expand-lg .navbar-nav .nav-link { font-size: 16px; padding: 10px 12px;}
.Landing_header .navbar-expand-lg .navbar-nav .nav-link.dropdown-toggle { padding-right:20px !important;}
.Landing_header .rt_btns {padding-top: 9px !important;}
.landing_tabs { max-width: 700px;}
.property_card.float_card { right: -97px;scale: .5;}
}
@media (max-width: 991px){
.property_card.float_card { bottom: 57px;}
.landining_main {min-height: 100px;}
.property_card.float_card {display: none; }
.landining_main.contac_t { background-size:50%  100%;}
.landining_main.terms-fixed {background-position: right top;
  min-height: 100px;
  background-size: cover;
  position: relative;
  z-index: 2;
}
.landining_main.terms-fixed::after
{
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -1;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
.terms-fixed  .landining_main_grid {grid-template-columns:1fr;}
}

@media (max-width: 768px) {
.landining_left h1 { font-size: 38px; }
.landining_main_grid {  grid-template-columns: auto; }
.landining_right {justify-content: center;display: flex;}
.landining_main { background-position: right top;min-height: 100px; background-size: cover;}
.sear_ch {display: grid;grid-gap: 30px;}
.landining_main.contac_t { background-size:cover; }
}


















