@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
*{
    padding: 0 ;
    margin: 0;
    box-sizing: border-box;
}
body{
    font-family: 'Inter', sans-serif;
    background: #F7F7FD;
}
a{
    text-decoration: none;
}

/* Login Page Started  */
.LoginSection{
    height: 100%;
}
.logPagesData{
    display: grid;
    grid-template-columns: 50% 50%;
    height: 100vh;
}

/* leftLogin */

.leftLogin{
    display: flex;
    flex-direction: column;
    padding: 45px 45px;
    justify-content: space-between;
    height: 100vh;
}
.TenantreazyTop {
    height: 100%;
    max-height: 100px;
}
.TenantreazyTop img {
    width: 100%;
    max-width: 150px;
}

.TenantreazyCenter {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
    gap: 20px;
}
.TenantreazyCenter .Heading {
    width: 100%;
}
.TenantreazyCenter .Heading h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: #21114D;
    margin-bottom: 10px;
}
.TenantreazyCenter .Heading h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #21114D;
}
.TenantForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
/* .TenantFormLabel{
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #21114D !important;
} */

/* .TenantFormInput input {
    padding: 10px 14px;
    background: #FFFFFF;
    border: 1px solid #E0DEF7;
    border-radius: 8px;
    color: #21114D;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
}
.TenantFormInput input:focus {
    background: #FFFFFF;
    border: 1px solid #E0DEF7;
    border-radius: 8px;
    outline: 0;
    box-shadow: none;
}
.TenantFormInput input:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}
.TenantFormInput input::placeholder {
    color: #21114D;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
} */
.tenantForgetdata {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tenatremeber {
    display: flex;
    align-items: center;
    gap: 10px;
}
.tenatremeber input:focus {
    border-color: #E0DEF7;
    outline: 0;
    box-shadow: none;
}
.tenatremeber label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #21114D;
}
.forgetpassword a {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #6341E0;
    text-decoration: none;
    cursor: pointer;
}
.forgetpassword a:hover {
    color: #6341e0a1;
}

.mainButton {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.fillBTN{
    padding: 10px 18px;
    width: 100%;
    height: 48px;
    background: #6341E0;
    border: 1px solid #6341E0;
    border-radius: 8px;
    text-decoration: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
}
.fillBTN:hover{
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    color: #21114D;
    transition: 0.3s;
}
.unfillBTN span img{
    width: 100%;
    max-width: 30px;
}
.unfillBTN{
    padding: 10px 18px;
    width: 100%;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    text-decoration: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #21114D;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 6px;
    transition: 0.3s;
}
.unfillBTN:hover{
    background: #6341E0;
    border: 1px solid #6341E0;
    color: #FFFFFF;
    transition: 0.3s;
}
.TenantSign {
    width: 100%;
}
.TenantSign span.txt1 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #21114D;
}
.TenantSign a {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #6341E0;
}
.TenantSign a:hover {
    color: #6341e0a1;
}
.TenantreazyFooter h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #21114D;
}


/* RightLogin */

.RightLogin{
    background: url(../../../Images/Loginimg.png);
    background-size: 100% 100%;
    height: 100vh;
}

/* Responsive started  */
@media (max-width: 991px){
    .logPagesData {
        height: unset;
        display: flex;
        flex-direction: column-reverse;
    }

}
@media (max-width: 420px){
    .leftLogin {
        padding: 30px 25px;
    }

}
@media (max-width: 380px){
    .TenantreazyCenter .Heading h6 {
        font-size: 14px;
    }
    .tenatremeber label {
        font-size: 12px;
    }
    .forgetpassword a {
        font-size: 12px;
    }
    .TenantSign span.txt1 {
        font-size: 14px;
    }
    .TenantSign a {
        font-size: 14px;
    }
    .unfillBTN {
        font-size: 14px;
    }
    .fillBTN {
        font-size: 14px;
    }
    .unfillBTN span img {
        max-width: 22px;
    }

}
/* Login Page Ended  */



/* Signup Page Started  */

.SignupSection{
    height: 100%;
}
.FormSmall{
    font-size: 14px;
    color: #475467;
    font-weight: 400;
}

/* Signup Page Ended */


/* Verify Page Started */
.Heading h6 span{
    font-size: 16px;
    color: #6341E0;
}
.VerifyOTP {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}
.verfyInpt{
    width: 100%;
    max-width: 66px;
    height: 100%;
    min-height: 66px;
    background: #E0DEF7;
    border-radius: 8px !important;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #6341E0;
    text-align: center;
    border: 1px solid #bdb7f9;
    box-shadow: 0px 0px 0px 0px #bdb7f9;
}
.verfyInpt:focus {
    color: #6341E0;
    background-color: #E0DEF7;
    border-color: #E0DEF7;
    outline: 0;
    box-shadow: none;
}

/* responsiv  */
@media (max-width: 991px){
    .verfyInpt {
        max-width: 58px;
        min-height: 58px;
    }

}
/* Verify Page Ended*/


/* ONBoardingStep Started  */
.ONBoardingStep{
    display: grid;
    grid-template-columns: 500px auto;
    height: 100vh;
}
.onboardStep{
    background-color: #21114D;
    height: 100%;
    padding: 45px 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.onBoardDash{
    background: #F7F7FD;
    height: 100vh;
}

/* OnBoard Details page started  */


/* onboard steps */
.onboardfoter{
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.onboardfoter h6{
    font-size: 14px;
    margin-bottom: 0;
    color: #FFFFFF;
    font-weight: 400;
}
.support {
    display: flex;
    align-items: center;
    gap: 10px;
}
.support img {
    width: 100%;
    max-width: 13px;
}
.support a {
    font-size: 14px;
    margin-bottom: 0;
    color: #FFFFFF;
    font-weight: 400;  
}

.Agent_account_Data {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.OpenAccount h4 {
    margin-top: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 0;
}
.OpenAccount h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #E0DEF7;
    margin-bottom: 0;
}
.creatAgentLogin h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    margin-bottom: 0;
}
.support {
    display: flex;
    align-items: center;
    gap: 8px;
}
.support i {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
}
.support h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    margin-bottom: 0;
}

/*Icon progressbar*/
.Agent_account_Data #progressbar {
    margin-bottom: 0;
    overflow: hidden;
    color: #FFFFFF;
    padding-left: 0px;
    /* margin-top: 30px; */
    display: flex;
    flex-direction: column;
    gap: 50px;
    z-index: 999;
} 
.Agent_account_Data #progressbar li {
    position: relative;
    display: flex;
    gap: 30px;
}
.Agent_account_Data #progressbar .step0:before {
    font-family: FontAwesome;
    content: ".";
    color: #FFFFFF;
    font-size: 60px;
    line-height: 0;
    text-align: center;
}
.Agent_account_Data ul#progressbar li a {
    text-align: left;
}
.Agent_account_Data #progressbar li:before {
    width: 40px;
    height: 40px;
    display: block;
    background: transparent;
    border-radius: 50%;
    margin: 0;
    padding: 0px;
    border: 1px solid #FFFFFF;
}
.Agent_account_Data #progressbar li:after {
    content: '';
    width: 2px;
    height: 56px;
    background: #ffffff94;
    position: absolute;
    left: 18px;
    top: 41px;
}
.Agent_account_Data #progressbar li:last-child:after {
    position: absolute;
    height: 0px;
}
.Agent_account_Data #progressbar li.active:before, #progressbar li.active:after {
    background: #6341E0;
    border: none;
}
.Agent_account_Data #progressbar li.active:before {
    font-family: FontAwesome;
    content: "\f00c";
    font-size: 20px;
    line-height: 2;
    text-align: center;
}

/* onBoardDash */

.onBoardDash{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}
.dashboardTop{
    padding: 85px 150px 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 70px;
}
.BoardingSteps {
    height: 100%;
}

/* .details_mails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
} */
.mailData{
    width: 100%;
}
.detailsHeading h4 {
    font-size: 18px;
    color: #21114D;  
    margin: 20px 0 5px;
    font-weight: 600;
    text-align: left;
}
.detailsHeading p {
    font-size: 14px;
    color: #21114D;
    margin-bottom: 10px;
    font-weight: 400;
}
.detailsForms {
    margin-top: 20px;
}


/* dashboardFooter */

.dashboardFooter {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: #FFFFFF;
    border-top: 1px solid #E0DEF7;
    align-items: center;
}
.dasunfill {
    padding: 12px;
    border-radius: 10px;
    border: 1px solid #E0DEF7;
    background: #FFFF;
    color: #21114D;
    font-size: 14px;
    font-weight: 600;
}
.DasunFill {
    padding: 12px 18px;
    border-radius: 10px;
    border: 1px solid #E0DEF7;
    background: #FFFF;
    color: #21114D;
    font-size: 14px;
    font-weight: 600;
}
.DasunFill:hover{
    background: #6341E0;
    color: #FFFF;
    border: 1px solid #6341E0;
}
.DashFill{
    background: #6341E0;
    color: #FFFF;
    border: 1px solid #6341E0;
    padding: 12px 18px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 10px;
}
.DashFill:hover{
    border: 1px solid #E0DEF7;
    background: #FFFF;
    color: #21114D;
}

.DashFotSteps {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.OpenAccount h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 0;
    text-align: left;
}
.OpenAccount h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #E0DEF7;
    margin-bottom: 0;
}

/*Icon progressbar*/
.DashFotSteps #progressbar {
    margin-bottom: 0;
    overflow: hidden;
    color: #FFFFFF;
    padding-left: 0px;
    display: flex;
    gap: 50px;
    z-index: 999;
} 
.DashFotSteps #progressbar li {
    position: relative;
    display: flex;
    gap: 30px;
}
.DashFotSteps #progressbar .step0:before {
    font-family: FontAwesome;
    content: ".";
    color: #E0DEF7;
    font-size: 60px;
    line-height: 0;
    text-align: center;
}
.DashFotSteps ul#progressbar li a {
    text-align: left;
}
.DashFotSteps #progressbar li:before {
    width: 40px;
    height: 40px;
    display: block;
    background: #F7F7FD;
    border-radius: 50%;
    margin: 0;
    padding: 0px;
    border: 1px solid #F7F7FD;
}
.DashFotSteps #progressbar li:after {
    content: '';
    width: 82px;
    height: 5px;
    position: absolute;
    left: 39px;
    top: 18px;
    background: #E0DEF7;
}
.DashFotSteps #progressbar li:last-child:after {
    position: absolute;
    height: 0px;
}
.DashFotSteps #progressbar li.active:before, #progressbar li.active:after {
    background: #6341E0;
    border: none;
}
.DashFotSteps #progressbar li.active:before {
    font-family: FontAwesome;
    content: "\f00c";
    font-size: 20px;
    line-height: 2;
    text-align: center;
}


/* Responsive  */
@media (max-width: 1399px){
    .dashboardTop {
        padding: 51px 80px;
    }
}

@media (max-width: 1199px){
    .DashFotSteps #progressbar {
        gap: 5px;
    }
    .DashFotSteps #progressbar li:before {
        width: 35px;
        height: 35px;
    }
    .DashFotSteps #progressbar li.active:before {
        font-size: 18px;
    }
    .DashFotSteps #progressbar li:after {
        width: 35px;
        height: 3px;
        left: 35px;
    }
    .DasunFill {
        font-size: 11px;
    }
    .DashFill {
        font-size: 11px;
    }
    .dashboardTop {
        padding: 45px 80px;
    }
    
}
@media (max-width: 1099px){
    .ONBoardingStep {
        grid-template-columns: 100%;
    }
    .onboardStep {
        gap: 50px;
    }
    
}
/* @media (max-width: 859px){
    .details_mails {
        display: block;
    }
     
} */
@media (max-width: 549px){
    .dashboardFooter {
        flex-direction: column;
        gap: 50px;
        padding: 35px;
    }
    .DasunFill {
        font-size: 14px;
    }
    .DashFill {
        font-size: 14px;
    }
     
}

@media (max-width: 420px){
    .detailsHeading p {
        font-size: 12px;
    }
    .detailsHeading h4 {
        font-size: 16px;
        margin-bottom: 6px;
    }
    .dashboardTop {
        padding: 40px 40px;
    }
    .OpenAccount h4 {
        font-size: 14px;
    }
    .OpenAccount h6 {
        font-size: 12px;
    }
     
}

/* OnBoard Details page Ended  */



/* OnBoard Current  page Started */

.CurrntTopDash{
    gap: 25px !important;
}
.DashTermsConditins {
    display: flex;
    align-items: baseline;
    gap: 15px;
    padding: 20px;
    background: #FFFFFF;
    border: 1px solid #E0DEF7;
    border-radius: 8px;
}
.termsText p{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #21114D;
}
.termsText span{
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #6341E0;
}
.CurrentDashButton {
    width: 100%;
    max-width: 250px;
}

/* OnBoard Current  page Ended  */

/* OnBoard Documents page Started  */

.dashuplodDocument {
    margin-top: 40px;
}
.dashuplodDocument h4 {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: #21114D;
}
.dashuplodDocument h4 span {
    color: #6341E0;
}
.clickUpload {
    text-align: center;
    background: #fff;
    border-radius: 8px;
    padding: 50px;
    border: 1px solid #E0DEF7;
    margin-top: 10px;
}
.uploadicon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: #EBEAFA;
    border: 1px solid #E0DEF7;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.uploadicon img {
    width: 100%;
    max-width: 30px;
}
.uploadText h6  {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #21114D;
    margin-bottom: 0;
}
.uploadText span {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #21114D;
}
.BankStatements {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 40px;
}
.bankProduct {
    display: flex;
    align-items: center;
    gap: 10px;
}
.bankProduct img {
    width: 100%;
    max-width: 100px;
}
.bankConnectBTN button span img{
    width: 100%;
    max-width: 20px;
    margin-right: 15px;
}
.hide{
    display: none;
}
.fillBTN:hover .hide{
    display: block;
}
.fillBTN:hover .show{
    display: none;
}

/* responsive  */
@media (max-width: 580px){
    .bankProduct {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

}

/* OnBoard Documents page Ended  */




